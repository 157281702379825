@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

@import "../src/assets/scss/ColorVariable.scss";
@import "../src/assets/scss/mixin.scss";

@import "../src/assets/scss/Typography.scss";
@import "../src/assets/scss/Button.scss";
@import "../src/assets/scss/Link.scss";
// @import "../src/assets/scss/Dropdown.scss";
@import "../src/assets/scss/Checkbox.scss";
@import "../src/assets/scss/TextField.scss";
@import "../src/components/CardSubHeader/style.scss";

body,
* {
  font-family: 'Noto Sans', sans-serif !important;
}

// media

// .body-wrapper{
//   @include bodyWrapperPadding;
// }

.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: rgba($black, 0.68);

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.material-symbols-outlined{
  font-family: 'Material Symbols Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: rgba($black, 0.68);

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.container {
  //max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  @include forPhoneOnly {
    padding: 0 15px;
  }
}
.container-home {
  @include responsiveWidth;
  margin: 0 auto; 
  padding: 0 15px;
  .MuiGrid-container {
    .MuiGrid-item {
      padding-top: 0;
    }
  }}

  .button-text {
    &.MuiButton-root {
      &.MuiButton-text {
        color: $primary;
        text-transform: initial;
        background: transparent !important;
        border-radius: 30px;
        font-weight: 700;
        font-size: 14px;
        padding: 0;
        margin-left: 24px;
        padding: 12px 22.5px;
        @include forPhoneOnly {
          margin-left: 0px;
          padding: 12px 17.5px;
        }
        &.button-liveChat{
          &.Mui-disabled{
            color: #c4c4c4;
            img{
              filter: grayscale(100%);
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  .button-text_rb{
    @extend .button-text;
    &.MuiButton-root {
      &.MuiButton-text {
        @include forPhoneOnly {
          padding: 12px 0;
    margin: 0 -10px 0 4px;
        }
      }}
}
.white-outline-button {
  &.MuiButton-root {
    &.MuiButton-text {
      color: $primary;
      text-transform: capitalize;
      background: transparent !important;
      border: 2px solid #F0F0F0;
    }
  }
}

.flag-img {
  width: 20px;
  margin-right: 10px;
}

.custom-select-dropdown {
  .MuiMenu-list {
    max-height: 300px;
    max-width: 500px;
  }
}

.sub-text {
  &.MuiTypography-root {
    color: rgba($black, 0.68);
    font-size: 11px;
    text-align: left;
  }
}
.sub-text_Arabic{
  &.MuiTypography-root {
    text-align: right !important;
  }
  @extend .sub-text;
} 

.link-text {
  &.MuiTypography-root {
    color:  rgba(0, 0, 0, 0.68) !important;
    font-size: 12px !important;
  }
}

.chat-button {
  &.MuiButton-root {
    display: flex;
    flex-direction: column;
    text-transform: none;
    box-shadow: 0px 3px 16px 2px rgb(0 0 0 / 12%);
    border-radius: 100px;
    width: 80px;
    height: 80px;

    p {
      font-size: 10px;
    }

  }
}
.heading-title{
  &.MuiTypography-h5{
    font-size: 36px;
    font-weight: 300;
    @include forPhoneOnly{
      font-size: 28px;
    }
  }
  
}

.font-14 {
  font-size: 14px !important;
}
.error_text {
  color: #F44336;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.align_center {
  text-align: center;
}
.password-validation-wrapper {
  background: $lightRed;
  border-radius: 4px;
  text-align: initial;
  .MuiTypography-root {
    // color:$red;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 500;
  }
  ul {
    padding-left: 18px;
    margin: 0;
    li {
      font-size: 12px;
      font-weight: 500;
      color: #524F4B;
      line-height: 1.7;
      list-style-image: initial;
       &.default {
         color: #524F4B;
         list-style-image: initial;
       }
       &.greenTick {
         color: #20C374;
         list-style-image: url(../public/images/Vector.svg);
       }
       &.redCross {
         color: #F44336;
         list-style-image: url(../public/images/crossIcon.svg);
       }
    }
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.expiry_header {
  color: #000000;
  text-align: center;
  font-size: 36px !important;
  font-weight: 500 !important;
  margin-top: 10px;
  letter-spacing: 0.03em;
  line-height: 45px !important;
}

.expiry_text {
 color: rgba(0, 0, 0, 0.68);
 font-family: 'Noto Sans'!important;
 font-style: normal !important;
  text-align: center;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 20px;
  letter-spacing: 0.03em;
  
}

.expiry_email {
  color: rgb(7, 7, 7);
  text-align: center;
  font-size: 1.8rem !important;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400 !important; 
  
}
.custom-header.customer-header2 {
  top: 59px!important;
}

input{
  &:-webkit-autofill{
    box-shadow: 0 0 0 100px white inset;
    -webkit-box-shadow: 0 0 0 100px white inset;
  }
  &:focus{
    &:-webkit-autofill{
      box-shadow: 0 0 0 100px white inset;
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}
.box-content{
 text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem; 
  height: 100%;
  width: 100%;
}
.checkbox-small-signup {
  margin-top: -7px !important;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400;
  font-size: 11px !important;
  line-height: 19px !important;
  letter-spacing: 0.4px !important;
}

.form-control-label-signup {
  font-size: 11px !important;
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start;
  height: auto;
  margin-top: -4px !important;
}